import React, { useEffect } from 'react'
import { useCookies } from 'react-cookie'

const ConsentCookies = () => {
  const isBrowser = typeof window !== "undefined"
  const [cookies] = useCookies()

  useEffect(()=>{
    // Consent Cookies Consent Notice start
    // if(process.env.NODE_ENV !== 'development') { 
      if(isBrowser && cookies?.adult) {
        window.semaphore = window.semaphore || []
        window.ketch=function(){
          window.semaphore.push(arguments)
        }
        var e = new URLSearchParams(document.location.search)
        var o = e.has("property") ? e.get("property") : "white_claw_zero_us"
        const script = document.createElement("script")
        script.src = `https://global.ketchcdn.com/web/v2/config/mag_global/${o}/boot.js`
        script.async = true
        script.defer = true
        script.type = "text/javascript"
        document.head.appendChild(script)
        script.onload = () => {
          window.ketch('once', 'consent', function (event) {
            if (event) {
              window.initialOptInStatus = event.purposes.behavioral_advertising;
            }
          });
          window.ketch('on', 'userConsentUpdated', function (event) {
            if (event) {
              const newOptInStatus = event.purposes.behavioral_advertising;
              if(!(window.initialOptInStatus === undefined && newOptInStatus === false) && 
                (window.initialOptInStatus !== newOptInStatus)){
                  window.location.reload(); // Reload the page
              } else {
                //console.log("Reload not required")
              }
            }
          });
      }
    }
    // }
    // Consent Cookies Consent Notice end
  },[cookies?.adult])

  return (<></>)
}

export default ConsentCookies