// core
import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import axios from 'axios'

// form validation
import { Formik, Form, Field } from "formik"
import * as Yup from "yup"

// components
import HiddenFields from './HiddenFields'
import FieldErrorMessage from './FieldErrorMessage'
import SuccessFailModal from "./SuccessFailModal"

// resources
import usaStatesList from "../../assets/resources/usa-states-list.json"
import { validateDobField, validateTimeStampField } from "../../assets/js"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { setContactUsFormSubmittedEvent, setSubscriptionSignUpEvent } from "../../assets/js/data-layer";
import { useConsent } from '../../components/ConsentContext'

// constants
import { ONLY_ALPHABET_CHARACTERS } from "../../constants/regexpPatterns"
import { FORM_OPTIONS } from "../../constants/formOptions"
import { BASE_URL, SUBSCRIPTION_SIGN_UP_BASE_URL } from "../../constants/baseUrl"
import { VALIDATION_EN, VALIDATION_ES } from "../../constants/formValidation"
import { SUBSCRIPTION_FORM } from "../../constants/subscriptionFormLocation"
import { ACTIVE_CAMPAIGN_TAG } from "../../constants"

const ContactUsForm = ({ activeDocMeta }) => {
    const { lang } = activeDocMeta ? activeDocMeta : "en-us"
    const validations = lang === "en-us" ? VALIDATION_EN : VALIDATION_ES
    const consentAccepted = useConsent()

    const ContactUsFormSchema = Yup.object().shape({
        email: Yup.string().required(validations.provideEmail).email(validations.provideValidEmail) .test('tld-validation', validations.provideValidEmail, (value) => {
            if (!value) return true;
            const domain = value.split('@')[1]; 
            if (domain) {
              const tld = domain.split('.').pop(); 
              if (tld.length > 4 || tld.includes('-')) {
                return false; 
              }
            }
            return true; 
          })
          .test('no-underscore',validations.provideValidEmail, (value) => {
            if (!value) return true; 
            const domain = value.split('@')[1];
            if (domain && domain.includes('_')) {
              return false; 
            }
            return true; 
          }),
        inquiryTypeID: Yup.string().required(validations.selectTopic),
        firstName: Yup.string()
            .required(validations.provideFirstName)
            .min(2, validations.tooShort)
            .max(32, validations.tooLong)
            .matches(ONLY_ALPHABET_CHARACTERS, validations.validFirstName),
        lastName: Yup.string()
            .required(validations.provideLastName)
            .min(2, validations.tooShort)
            .max(32, validations.tooLong)
            .matches(ONLY_ALPHABET_CHARACTERS, validations.validLastName),
        birthDate: Yup.string()
            .required(validations.enterBday)
            .typeError(validations.validBday)
            .test('valid-birth-date', validations.validBday, (value) => {
                const birthDateRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|[1-2]\d|3[0-1])\/\d{4}$/
                if (!value) return true // Allow empty values, Yup.date() already checks for date format
                return birthDateRegex.test(value)
            })
            .test("birthdate", validations.validDate, () => {
                return checkIsDateValid(birthDayValue);
            })
            .test("valid-birth-date", validations.legalAge, value => {
                return checkLegalAge(value)
        }),
        message: Yup.string().required(validations.provideMsg),
        batchNumber: Yup.string().when(["inquiryTypeID", "extraFieldsCheckbox"], {
            is: (inquiryTypeID, extraFieldsCheckbox) => inquiryTypeID === "100000003" && extraFieldsCheckbox === false,
            then: Yup.string()
            .required(validations.provideBatchCode)
            .min(9, validations.batchNumberMinLeng),
            otherwise: Yup.string().notRequired(),
        }),
        timeStamp: Yup.string().when(["inquiryTypeID", "extraFieldsCheckbox"], {
            is: (inquiryTypeID, extraFieldsCheckbox) => inquiryTypeID === "100000003" && extraFieldsCheckbox === false,
            then: Yup.string()
            .required(validations.provideTimeStamp)
            .min(5, validations.timeStampMinLeng),
            otherwise: Yup.string().notRequired(),
        }),
        lineNumber: Yup.string().when(["inquiryTypeID", "extraFieldsCheckbox"], {
            is: (inquiryTypeID, extraFieldsCheckbox) => inquiryTypeID === "100000003" && extraFieldsCheckbox === false,
            then: Yup.string()
            .required(validations.provideLineNumber)
            .min(2, validations.lineNumberMinLeng),
            otherwise: Yup.string().notRequired(),
        }),
        usaStatesList: Yup.string()
            .required(validations.pleaseSelectStateOfPurchase),
        packageType: Yup.string().when("extraFieldsCheckbox", {
            is: (val) => val === true,
            then: Yup.string().required(validations.pleaseSelectPackageType),
            otherwise: Yup.string().notRequired(),
        }),
         packageQuantity: Yup.string().when("extraFieldsCheckbox", {
            is: (val) => val === true,
            then: Yup.string().required(validations.selectPackageQuantity),
            otherwise: Yup.string().notRequired(),
        }),
        flavorName: Yup.string().when("extraFieldsCheckbox", {
            is: (val) => val === true,
            then: Yup.string().required(validations.provideFlavorName),
            otherwise: Yup.string().notRequired(),
        })
    })

    const [birthDayValue, setBirthDayValue] = useState("")
    const [timeStampValue, setTimeStampValue] = useState("")
    const [batchNumberValue, setBatchNumberValue] = useState("")
    const [lineNumberValue, setLineNumberValue] = useState("")
    const [batchCharValidationMsg, setBatchCharValidationMsg] = useState("")
    const [lineNumberValidationMsg, setLineNumberValidationMsg] = useState("")
    const [extraFieldsContainer, setExtraFieldsContainer] = useState(false)
    const [extraFieldsBtnText, setExtraFieldsBtnText] = useState(validations.dontHaveBatchNumber)

    // show success fail modal functions
    let [userName, setUserName] = useState(false)
    let [modalBodyCopy, setModalBodyCopy] = useState("")
    const [showSuccessFailModal, setShowSuccessFailModal] = useState(false)
    const closeSuccessFailModal = () => setShowSuccessFailModal(false)

    // toggle btn
    const toggleExtraFieldsContainer = (e) => {

        if (!extraFieldsContainer) {
            setBatchNumberValue(validations.naDD)
            setTimeStampValue(validations.naDD)
            setLineNumberValue(validations.naDD)
            setBatchCharValidationMsg("")
            setLineNumberValidationMsg("")
            setExtraFieldsBtnText(validations.enterProductDetails)
        } else {
            setBatchNumberValue("")
            setTimeStampValue("")
            setLineNumberValue("")
            setExtraFieldsBtnText(validations.dontHaveBatchNumber)
        }

        setExtraFieldsContainer(!extraFieldsContainer)
    }

    const handleBirthDateChange = (e) => {
        const output = validateDobField(e)
        setBirthDayValue(output.join('').substr(0, 10))
    }

    const handleBatchNumberChange = (e) => {
        let enteredValue = e.target.value.toUpperCase()
        let validatedValue = ''

        // If input is empty clear error message
        if (enteredValue === "") { setBatchCharValidationMsg("") }

        // For loop validation conditions for each char
        for (let i = 0; i < enteredValue.length; i++) {
            let char = enteredValue.charAt(i)

            // By the string index validate each char Ex: "K1522L9GL"
            // Validate K then 1 then 5 and so on.

            // Alphabet character from A to M, excluding I
            if (i === 0 && /^[A-HJ-M]+$/.test(char)) {
                setBatchCharValidationMsg("")
                validatedValue += char
            } else if (i === 0) {
                setBatchCharValidationMsg(validations.batchValidationChar_1)
            }
            // 1 or 2 or 3 only
            if (i === 1 && /^[123]+$/.test(char)) {
                setBatchCharValidationMsg("")
                validatedValue += char
            } else if (i === 1) {
                setBatchCharValidationMsg(validations.batchValidationChar_2)
            }
            // Any number 0 to 9
            if (i === 2 && /^[0-9]+$/.test(char)) {
                setBatchCharValidationMsg("")
                validatedValue += char
            } else if (i === 2) {
                setBatchCharValidationMsg(validations.batchValidationChar_3_or_5)
            }
            // 2 or 3 only
            if (i === 3 && /^[23]+$/.test(char)) {
                setBatchCharValidationMsg("")
                validatedValue += char
            } else if (i === 3) {
                setBatchCharValidationMsg(validations.batchValidationChar_4)
            }
            // Any number 0 to 9
            if (i === 4 && /^[0-9]+$/.test(char)) {
                setBatchCharValidationMsg("")
                validatedValue += char
            } else if (i === 4) {
                setBatchCharValidationMsg(validations.batchValidationChar_3_or_5)
            }
            // Any number or letter. No special characters
            if (i === 5 && /^[0-9A-Z]+$/.test(char)) {
                setBatchCharValidationMsg("")
                validatedValue += char
            } else if (i === 5) {
                setBatchCharValidationMsg(validations.batchValidationChar_6_or_7)
            }
            // Any number or letter. No special characters
            if (i === 6 && /^[0-9A-Z]+$/.test(char)) {
                setBatchCharValidationMsg("")
                validatedValue += char
            } else if (i === 6) {
                setBatchCharValidationMsg(validations.batchValidationChar_6_or_7)
            }
            // Any letter. No number or special characters
            if (i === 7 && /^[A-Z]+$/.test(char)) {
                setBatchCharValidationMsg("")
                validatedValue += char
            } else if (i === 7) {
                setBatchCharValidationMsg(validations.batchValidationChar_8_or_9)
            }
            // Any letter. No number or special characters
            if (i === 8 && /^[A-Z]+$/.test(char)) {
                setBatchCharValidationMsg("")
                validatedValue += char
            } else if (i === 8) {
                setBatchCharValidationMsg(validations.batchValidationChar_8_or_9)
            }
        }
        setBatchNumberValue(validatedValue)
    }

    const handleTimeStampChange = (e) => {
        const output = validateTimeStampField(e)
        setTimeStampValue(output.join('').substr(0, 5))
    }

    const handleLineNumberChange = (e) => {
        let enteredValue = e.target.value.toUpperCase()
        let validatedValue = ''

        // If input is empty clear error message
        if (enteredValue === "") { setLineNumberValidationMsg("") }

        // For loop validation conditions for each char
        for (let i = 0; i < enteredValue.length; i++) {
            let char = enteredValue.charAt(i)

            // Any number or letter. No special characters
            if (/^[0-9A-Z]+$/.test(char)) {
                setLineNumberValidationMsg("")
                validatedValue += char
            } else {
                setLineNumberValidationMsg(validations.lineNumberValidationFormat)
            }
        }
        setLineNumberValue(validatedValue)
    }
    const checkIsDateValid = (dateString) => {
        var parts = dateString.split("/");
        var day = parseInt(parts[1], 10);
        var month = parseInt(parts[0], 10);
        var year = parseInt(parts[2], 10);

        // Check the ranges of month and year
        if (year < 1000 || year > 3000 || month === 0 || month > 12) {
            return false;
        }

        // Check the number of days in the month
        var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        if (month === 2 && year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0)) {
            // Leap year
            if (day > 29) {
                return false;
            }
        } else {
            if (day > monthLength[month - 1]) {
                return false;
            }
        }

        return true;
    }


    const checkLegalAge = (value) => {
        const bdayDate = new Date(value);
        const today = new Date();

        const age = Math.floor((today-bdayDate) / (365.25 * 24 * 60 * 60 * 1000));
        return age >= 21;
    }

    const isBrowser = typeof window !== "undefined"
    // get ? query value
    const queryParams = isBrowser && new URLSearchParams(window.location.search)
    const subject = isBrowser && queryParams.get("subject")

    // preset default inquiryTypeID value
    const presetInquiryTypeID = subject ? "100000013" : ""

    // const [segmentID, setSegmentID] = useState("")

    useEffect(() => {
        // const waitWindowAnalyticsObj = () => {
        //   if (typeof window !== "undefined") {
        //     // eslint-disable-next-line react-hooks/exhaustive-deps
        //     let analyticsLocalObj = window.analytics
        //     if (analyticsLocalObj && consentAccepted) {

        //       // prevent page down in incognito mood when clicking on "Confirm My Choices" on OneTrust
        //       const  anonymous_id = analyticsLocalObj.hasOwnProperty('user')
        //           ? analyticsLocalObj.user().anonymousId() : analyticsLocalObj.group().hasOwnProperty('anonymousId')
        //               ? analyticsLocalObj.group().anonymousId() : ''

        //       setSegmentID(anonymous_id)

        //     } else {
        //       setTimeout(waitWindowAnalyticsObj, 250)
        //     }
        //   }
        // }
        // waitWindowAnalyticsObj()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [consentAccepted])

    const sendSubscriptionDataToActiveCampaign = (data) => {
        axios({
            method: "POST",
            url: SUBSCRIPTION_SIGN_UP_BASE_URL.PROD,
            data: JSON.stringify(data),
            cache: false,
            headers: {
                "Content-Type": "application/json; charset=utf-8"
            }
        }).then(function (response) {
            // data layer
            setSubscriptionSignUpEvent(SUBSCRIPTION_FORM.CONTACT_US_PAGE, consentAccepted)
        }).catch(function(){
            // handle error
        })
    }

    let isSubmitBlocked = false

    function preventNumbersAndSpecialChars(event) {
        const input = event.target;
        const char = event.key;
        if (!/[a-zA-Z]/.test(char) && char !== ' ' && char !== '-') {
          event.preventDefault(); 
          return;
        }
        if (input.value === '' && (char === ' ' || char === '-')) {
          event.preventDefault();
        }
      }
    const handleKeyPress = (event) => {
        const allowedChars = /^[a-zA-Z0-9@._-]$/;
        const allowedKeys = [
            "Backspace", "ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown"
        ];

        if (event.key === " " || !allowedChars.test(event.key) && !allowedKeys.includes(event.key)) {
            event.preventDefault();
        }
    };
    return(
        <>
            {/* Form */}
            <Formik
                initialValues={ // initial form values
                    {
                        email: "",
                        inquiryTypeID: presetInquiryTypeID,
                        firstName: "",
                        lastName: "",
                        birthDate: "",
                        message: "",
                        optInCheckBox: false,
                        batchNumber: "",
                        timeStamp: "",
                        lineNumber: "",
                        usaStatesList: "",
                        extraFieldsCheckbox: false,
                        packageType: "",
                        packageQuantity: "",
                        flavorName: ""
                    }
                }

                validationSchema={ContactUsFormSchema}

                onSubmit={(values, { setSubmitting, resetForm }) => {
                    let extraDetails = []

                    // Add segmentId to extraDetails[]
                    // extraDetails.push({ KeyName: 'segmentId', Value: segmentID })
                    extraDetails.push({ KeyName: 'Message', Value: values.message.trim() })

                    if(values.inquiryTypeID === '100000003') {
                        extraDetails.push({ KeyName: 'BatchCode', Value: values.batchNumber.trim() })
                        extraDetails.push({ KeyName: 'TimeStamp', Value: values.timeStamp.trim() })
                        extraDetails.push({ KeyName: 'LineNumber', Value: values.lineNumber.trim() })
                        extraDetails.push({ KeyName: 'StoreState', Value: values.usaStatesList.trim() })
                        if (values.extraFieldsCheckbox === true) {
                            extraDetails.push({ KeyName: 'PackageType', Value: values.packageType.trim() })
                            extraDetails.push({ KeyName: 'PackageQuantity', Value: values.packageQuantity.trim() })
                            extraDetails.push({ KeyName: 'PackageFlavor', Value: values.flavorName.trim() })
                        }
                    }

                    extraDetails.push({ KeyName: 'StoreState', Value: values.usaStatesList.trim() })

                    let data = {
                        ApplicationID: 1046,
                        Email: values.email.trim(),
                        InquiryTypeID: values.inquiryTypeID,
                        FirstName: values.firstName.trim(),
                        LastName: values.lastName.trim(),
                        BirthDate: values.birthDate.trim(),
                        Message: values.message.trim(),
                        OptInCheckBox: values.optInCheckBox,
                        ExtraDetails: extraDetails
                    }

                    //data layer data
                    const dlData = {
                        topicId: values.inquiryTypeID,
                        topicName: FORM_OPTIONS.get(values.inquiryTypeID),
                        batchn: values.batchNumber.trim(),
                        sop: values.usaStatesList.trim(),
                        pt: values.packageType.trim(),
                        pq: values.packageQuantity.trim(),
                        contact_us_flavor_name: values.flavorName.trim()
                    }

                    const subscriptionData = {
                        ListID: 239,
                        Key: 'rgkx5ee6zl',
                        FirstName: values.firstName.trim(),
                        LastName: values.lastName.trim(),
                        Email: values.email.trim(),
                        birthdate: values.birthDate.trim(),
                        state: values.usaStatesList.trim(),
                        Tag: ACTIVE_CAMPAIGN_TAG.CONTACT_US,
                        Consent: "true"
                    }

                    if(!isSubmitBlocked){
                        isSubmitBlocked = true

                        axios({
                            method: "POST",
                            url: BASE_URL.PROD,
                            data: JSON.stringify(data),
                            cache: false,
                            headers: {
                              "Content-Type": "application/json; charset=utf-8"
                            }
                        }).then(function (response) {
                            isSubmitBlocked = false

                            if(values.optInCheckBox){
                                // submit to ActiveCampaign
                                sendSubscriptionDataToActiveCampaign(subscriptionData)
                            }

                            // set data to data layer
                            setContactUsFormSubmittedEvent(dlData, consentAccepted)

                            // set data for success fail modal
                            setUserName(values.firstName.trim())
                            setModalBodyCopy(validations.messageYes)
                            setShowSuccessFailModal(true)

                            // Reset birthday
                            setBirthDayValue("")
                            // Reset timeStamp
                            setTimeStampValue("")
                            // Reset batchCode
                            setBatchNumberValue("")
                            // Reset lineNumber
                            setLineNumberValue("")
                            // Reset form
                            resetForm()

                        }).catch(function() {
                            isSubmitBlocked = false

                            // set data for success fail modal
                            setUserName(values.firstName.trim())
                            setModalBodyCopy(validations.messageNo)
                            setShowSuccessFailModal(true)

                            // Reset birthday
                            setBirthDayValue("")
                            // Reset timeStamp
                            setTimeStampValue("")
                            // Reset batchCode
                            setBatchNumberValue("")
                            // Reset lineNumber
                            setLineNumberValue("")
                            // Reset form
                            resetForm()
                        })

                        setSubmitting(false)
                    }
                }}
            >
                {/* Form content */}
                {({ values, errors, submitCount, setFieldValue }) => {
                    return (
                        <Form id='frm-contact-form' autoComplete='off'>
                            <div className="row">
                                <div className="col-md-6 form-group control-group">
                                    {/* Email */}
                                    <Field type="email" id="form-email" className="form-control" name="email" placeholder={validations.email} onKeyPress={handleKeyPress}/>

                                    {submitCount > 0 && errors.email !== "" ? (
                                        <FieldErrorMessage error={errors.email} />
                                    ) : null}
                                    {/* Eof email */}
                                </div>

                                <div className="col-md-6 form-group control-group">
                                    {/* Inquery type */}
                                    <label className="select">
                                        <Field
                                            as="select"
                                            className="form-control"
                                            name="inquiryTypeID"
                                        >
                                            <option value="">{validations.pleaseSelectTopic}</option>
                                            <option value="100000003" data-subject={validations.qualityConcern}>
                                                {validations.qualityConcern}
                                            </option>
                                            <option value="100000012" data-subject={validations.cantFindProducts}>
                                                {validations.cantFindProducts}
                                            </option>
                                            <option value="100000005" data-subject={validations.sponsorRequest}>
                                                {validations.sponsorRequest}
                                            </option>
                                            <option value="100000007" data-subject={validations.partnerMedia}>
                                                {validations.partnerMedia}
                                            </option>
                                            <option value="100000002" data-subject={validations.ingredientNutr}>
                                                {validations.ingredientNutr}
                                            </option>
                                            <option value="100000006" data-subject={validations.coupon}>
                                                {validations.coupon}
                                            </option>
                                            <option value="100000001" data-subject={validations.prodFlavFeedback}>
                                                {validations.prodFlavFeedback}
                                            </option>
                                            <option value="100000008" data-subject={validations.expDate}>
                                                {validations.expDate}
                                            </option>
                                            <option value="100000011" data-subject={validations.sweepsContInq}>
                                                {validations.sweepsContInq}
                                            </option>
                                            <option value="100000000" data-subject={validations.other}>
                                                {validations.other}
                                            </option>
                                        </Field>
                                        <FontAwesomeIcon icon={faChevronDown} className="fa-solid" />
                                        {submitCount > 0 && errors.inquiryTypeID !== "" ? (
                                            <FieldErrorMessage error={errors.inquiryTypeID} />
                                        ) : null}
                                    </label>
                                    {/* Eof inquery type */}
                                </div>
                            </div>

                            {/* Hidden Fields */}
                            <HiddenFields
                                inquiryTypeID={values.inquiryTypeID}
                                lang={lang}
                                validations={validations}

                                submitCount={submitCount}
                                errors={errors}
                                toggleExtraFieldsContainer={toggleExtraFieldsContainer}
                                extraFieldsBtnText={extraFieldsBtnText}
                                handleBatchNumberChange={handleBatchNumberChange}
                                extraFieldsContainer={extraFieldsContainer}
                                values={values}
                                batchCharValidationMsg={batchCharValidationMsg}
                                handleTimeStampChange={handleTimeStampChange}
                                timeStampValue={timeStampValue}
                                handleLineNumberChange={handleLineNumberChange}
                                lineNumberValue={lineNumberValue}
                                lineNumberValidationMsg={lineNumberValidationMsg}
                                batchNumberValue={batchNumberValue}
                                setFieldValue={setFieldValue}
                            />
                            {/* Eof hidden fields */}

                            <div className="row">
                                <div className="col-md-6 form-group control-group">
                                    {/* First Name */}
                                    <Field
                                        type="text"
                                        id="form-firstname"
                                        className="form-control"
                                        name="firstName"
                                        placeholder={validations.firstNameDD}
                                        onKeyPress={(event) => preventNumbersAndSpecialChars(event)} // Use onKeyPress instead of onChange
                                        onChange={(event) => setFieldValue("firstName", event.target.value)} // Formik onChange
                                    />
                                    {submitCount > 0 && errors.firstName !== "" ? (
                                        <FieldErrorMessage error={errors.firstName} />
                                    ) : null}
                                    {/* Eof first Name */}
                                </div>

                                <div className="col-md-6 form-group control-group">
                                    {/* Last Name */}
                                    <Field
                                        type="text"
                                        id="form-lastname"
                                        className="form-control"
                                        name="lastName"
                                        placeholder={validations.lastNameDD}
                                        onKeyPress={(event) => preventNumbersAndSpecialChars(event)} 
                                        onChange={(event) => setFieldValue("lastName", event.target.value)}
                                    />

                                    {submitCount > 0 && errors.lastName !== "" ? (
                                        <FieldErrorMessage error={errors.lastName} />
                                    ) : null}
                                    {/* Eof last name */}
                                </div>

                                <div className="col-md-6 form-group control-group">
                                    {/* Birthday */}
                                    <Field
                                        type="text"
                                        id="form-birthdate"
                                        className="form-control"
                                        name="birthDate"
                                        placeholder={validations.birthDateDD}
                                        onChange={handleBirthDateChange}
                                        value={(values.birthDate = birthDayValue)}
                                    />

                                    {submitCount > 0 && errors.birthDate !== "" ? (
                                        <FieldErrorMessage error={errors.birthDate} />
                                    ) : null}
                                    {/* Eof birthday */}
                                </div>

                                <div className='col-md-6 form-group control-group'>
                                    {/* State */}
                                    <label className='select'>
                                        <Field
                                            className="form-control"
                                            name="usaStatesList"
                                            as="select"
                                            placeholder="State"
                                        >
                                            <option value="">{validations.stateDD}</option>
                                            {usaStatesList && Object.keys(usaStatesList).map( state =>
                                                <option key={state} value={state}>{state}</option>
                                            )}
                                        </Field>
                                        <FontAwesomeIcon icon={faChevronDown} className="fa-solid arrow-icon" />
                                    </label>

                                    {submitCount > 0 && errors.usaStatesList?
                                        <div className="help-block with-errors">
                                            <ul role="alert" className={"list-unstyled " + (!errors.usaStatesList ? "d-none" : "")}>
                                            <li>{errors.usaStatesList}</li>
                                            </ul>
                                        </div>
                                    : null}
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 form-group form-text-field">
                                    {/* Message */}
                                    <Field
                                        as="textarea"
                                        id="form-message"
                                        className="form-control"
                                        name="message"
                                        rows="5"
                                        type="text"
                                        placeholder={validations.msgDD}
                                    />

                                    {submitCount > 0 && errors.message !== "" ? (
                                        <FieldErrorMessage error={errors.message} />
                                    ) : null}
                                    {/* Eof message */}
                                </div>

                                <div className='col-12 col-xl-10 form-group'>
                                    {/* Agree and OptIn Newsletter Checkbox */}
                                    <div className='subscribe-checkbox checkbox'>
                                    <label className="active-campaing-checkbox-label">
                                        {/* OptIn CheckBox NewsLetter */}
                                        <Field id="opt-in-checkbox" type="checkbox" name="optInCheckBox" />
                                        &nbsp;
                                        <span className="checkmark"></span>
                                        &nbsp;
                                        <span className="label-copy" dangerouslySetInnerHTML={{__html: validations.tickBoxDD }} />
                                    </label>
                                    {submitCount > 0 && errors.optInCheckBox &&
                                        <FieldErrorMessage error={errors.optInCheckBox} />
                                    }
                                    </div>
                                </div>

                                <div className="col-12 form-group form-text-field form-btn-container d-flex justify-content-end">
                                    {/* Submit Button */}
                                    <button className="form-btn" id="submit-contact-form">
                                        {validations.submitBtn}
                                    </button>
                                    {/* Eof submit button */}
                                </div>
                            </div>

                            {/* Success Fail Component */}
                            <SuccessFailModal
                                showSuccessFailModal={showSuccessFailModal}
                                closeSuccessFailModal={closeSuccessFailModal}
                                userName={userName}
                                modalBodyCopy={modalBodyCopy}
                                activeLang={lang}
                            />
                        </Form>
                    )
                }}
                {/* Eof form content */}
            </Formik>
        </>
    )
}

export default ContactUsForm